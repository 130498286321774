import { Component } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Observable } from 'rxjs';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
// import { FCM } from '@ionic-native/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { LoaderService } from './services/loader/loader.service';
import { ToastComponent } from './shared/toast/toast.component';
import { AreaService } from './services/Area/area.service';
import { UserService } from './services/user/user.service';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  user: Observable<firebase.User>
  loadingFlag:boolean = false;
  // lottieConfig:AnimationOptions = {
  //   path: 'assets/lottie/loading.json',
  // };
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private fcm: FCM,
    private titleSer: Title,
    public loader: LoaderService,
    private meta:Meta,
    private afAuth: AngularFireAuth,
    private toast:ToastComponent,
    public areaService:AreaService,
    private userService:UserService,
    private navCtrl:NavController
  ) {
    this.titleSer.setTitle('Laboratory App Solutions (OPC) PVT. LTD')
    this.meta.updateTag({ name: 'description', content:`Laboratory App solution Pvt. Ltd. widely known as Laboratory app, is a registered company
    incorporated with a vision to make available a single platform where various repair and service
    needs of domestic & office clients are met with guaranteed quality standard at affordable cost..`});
    //facebook
    this.meta.updateTag({name:'og:type', content:"website"})
    this.meta.updateTag({name:"og:url",content:'https://fixitnow.in/'})
    this.meta.updateTag({name:"og:title",content:"Laboratory App Solutions (OPC) PVT. LTD"})
    this.meta.updateTag({name:"og:description",content:`Laboratory App solution Pvt. Ltd. widely known asLaboratory app, is a registered company
    incorporated with a vision to make available a single platform where various repair and service
    needs of domestic & office clients are met with guaranteed quality standard at affordable cost.`})
    this.meta.updateTag({name:"og:image",content:"https://fixitnow.in/assets/img/login3.png"})
    //twitter
    this.meta.updateTag({name:'twitter:card', content:"summary_large_image"})
    this.meta.updateTag({name:"twitter:url",content:'https://fixitnow.in/'})
    this.meta.updateTag({name:"twitter:title",content:"Laboratory App Solutions (OPC) PVT. LTD"})
    this.meta.updateTag({name:"twitter:description",content:`Laboratory App solution Pvt. Ltd. widely known as Own4you, is a registered company
    incorporated with a vision to make available a single platform where various repair and service
    needs of domestic & office clients are met with guaranteed quality standard at affordable cost.`})
    this.meta.updateTag({name:"twitter:image",content:"https://fixitnow.in/assets/img/login3.png"})
    
  //   this.lottieConfig = {
  //     path: 'assets/lottie/loader.json',
  //     renderer: 'canvas',
  //     autoplay: true,
  //     loop: true
  // };
    setTimeout(() => {
      this.loadingFlag = true;
  }, 800);
    this.initializeApp();
    this.user = this.afAuth.authState

  }

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.splashScreen.hide();
      if(localStorage.getItem("userProfile")){
        this.userService.getUserDetails(JSON.parse(localStorage.getItem("userProfile")).id).then((ress:any)=>{
          if(ress && ress.status == false){
            localStorage.removeItem("userProfile");
            localStorage.removeItem("loginUser");
            this.navCtrl.navigateRoot("/login");
            this.userService.toast.presentToastError("Your Account is Deactivated Please contact Admin.")
          }
        }).catch((err)=>{
          localStorage.removeItem("userProfile");
          localStorage.removeItem("loginUser");
          this.navCtrl.navigateRoot("/login");
          this.userService.toast.presentToastError("Your Account is Deactivated Please contact Admin.")
        })  
      }
      if (this.platform.is('cordova')) {
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString("#00003a");
        setTimeout(() => {
          this.splashScreen.hide();
        }, 2000);
        this.fcm.onNotification().subscribe((data) => {
          if (data.wasTapped) {
            console.log("in background", JSON.stringify(data));
          } else {
            console.log("normal", data);
            let not=JSON.parse(JSON.stringify(data))

            console.log("sdfsdfsfsdf", data.id, data.route);
            console.log("in foreground", not);
            this.toast.presentToast(not.body)
          }
        })

      }
    });
  }

}
